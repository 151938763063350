<template>
  <div class="questions">
    <promotional-bar v-if="this.step >= 5" />
    <div class="col-lg-8 offset-lg-2 pt-5">
      <b-container v-if="this.step < 5" class="p-4 p-md-5 bg-white shadow-lg">
        <p>{{ stepsData[this.step].question }}</p>
        <h3>{{ stepsData[this.step].title }}</h3>
        <img src="../assets/questions/divider.png" />
        <div class="mt-4 mt-md-5">
          <div class="text-center" v-if="this.step < 3">
            <b-row class="text-center">
              <b-col
                cols="5"
                lg="2"
                v-for="(data, index) in stepsData[this.step].options"
                :key="index"
                @click="nextStep(data.value)"
                class="mx-auto"
              >
                <img
                  :src="require(`../assets/questions/${data.imgSrc}`)"
                  class="cursorPointer"
                  width="100%"
                />
                <p class="text-center mt-3">
                  <span class="cursorPointer questionText"
                    >{{ data.qTitle }}
                  </span>
                </p>
              </b-col>
            </b-row>
          </div>
          <div v-else-if="this.step < 5">
            <div>
              <b-form-group>
                <b-form-radio-group
                  v-model="selected"
                  :options="stepsData[this.step].options"
                  @change="nextStep(selected)"
                  class="mb-3 d-flex flex-column"
                  value-field="value"
                  text-field="text"
                >
                </b-form-radio-group>
              </b-form-group>
            </div>
          </div>
        </div>
      </b-container>
      <b-container v-else class="p-4 p-md-5 bg-white shadow-lg">
        <div><Form :answers="this.answers" /></div>
      </b-container>
    </div>
  </div>
</template>

<script>
import Form from "./Form.vue";
import PromotionalBar from "./PromotionalBar.vue";
export default {
  name: "Questions",
  data() {
    return {
      step: 0,
      selected: "",
      answers: ["3412"],
      stepsData: [
        {
          question: "Vraag 1 van 5",
          title: "Wat voor type woning heeft u?",
          options: [
            {
              imgSrc: "home1.png",
              qTitle: "Vrijstaand huis",
              value: "3415",
            },
            {
              imgSrc: "home.png",
              qTitle: "Een rijtjeshuis of hoekwoning",
              value: "3418",
            },
            {
              imgSrc: "modern-house.png",
              qTitle: "2 onder 1 kap",
              value: "3421",
            },
            {
              imgSrc: "real-estate.png",
              qTitle: "Appartement",
              value: "3424",
            },
            {
              imgSrc: "real-estate.png",
              qTitle: "Bedrijf",
              value: "3565",
            },
          ],
        },
        {
          question: "Vraag 2 van 5",
          title: "Hoeveel toegangen heeft uw woning?",
          options: [
            {
              imgSrc: "1.png",
              qTitle: "1 toegang",
              value: "3427",
            },
            {
              imgSrc: "2.png",
              qTitle: "2 toegangen",
              value: "3430",
            },
            {
              imgSrc: "3OfMeer.png",
              qTitle: "3 toegangen of meer",
              value: "3433",
            },
          ],
        },
        {
          question: "Vraag 3 van 5",
          title: "Hoeveel kamers heeft uw woning?",
          options: [
            {
              imgSrc: "1-2.png",
              qTitle: "1-2 kamers",
              value: "3436",
            },
            {
              imgSrc: "3-4.png",
              qTitle: "3-4 kamers",
              value: "3439",
            },
            {
              imgSrc: "5OfMeer.png",
              qTitle: "5 of meer kamers",
              value: "3442",
            },
          ],
        },
        {
          question: "Vraag 4 van 5",
          title:
            "Op welke termijn zou u het alarmsysteem willen laten installeren?",
          options: [
            { text: "Zo snel mogelijk", value: "3445" },
            { text: "Binnen nu en twee maanden", value: "3448" },
            { text: "Dat weet ik nog niet/ in overleg", value: "3451" },
          ],
        },
        {
          question: "Vraag 5 van 5",
          title: "Wilt u camerabewaking?",
          options: [
            { text: "Ja", value: "3454" },
            { text: "Nee", value: "3457" },
            { text: "In overweging", value: "3460" },
          ],
        },
      ],
    };
  },
  methods: {
    nextStep(value) {
      this.step++;
      this.answers.push(value);
    },
  },
  components: { Form, PromotionalBar },
};
</script>

<style lang="scss">
*::-webkit-scrollbar {
  display: none;
}
.questions {
  background: url(../assets/background.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: calc(100vh + 70px);
  @media only screen and (max-width: 766px) {
    height: calc(100vh + 200px) !important;
  }
  @media only screen and (max-width: 376px) {
    height: calc(100vh + 400px) !important;
  }
  & p {
    color: #337ab7 !important;
    @media only screen and (max-width: 766px) {
      font-size: 12px !important;
    }
  }
  & h3 {
    font-family: Roboto-Bold !important;
    color: #143148 !important;
    @media only screen and (max-width: 766px) {
      font-size: 23px !important;
    }
  }
  .questionText {
    font-size: 14px;
  }
}
</style>
