<template>
  <div class="question">
    <Navbar />
    <QuestionsSection />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
import QuestionsSection from "../components/QuestionsSection.vue";
export default {
  name: "Questions",
  components: {
    Navbar,
    QuestionsSection,
  },
};
</script>
