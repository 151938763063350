<template>
  <b-container class="form">
    <p>Bedankt voor uw deelname aan de veiligheidscheck.</p>
    <h4>Wij adviseren u graag gratis en vrijblijvend over uw situatie.</h4>
    <img src="../assets/questions/divider.png" />
    <div class="mt-4">
      <b-form @submit.prevent="onSubmit">
        <div class="row">
          <!-- firstName -->
          <div class="col-lg-6 col-sm-12">
            <b-form-input
              v-model="form.firstname"
              :state="fNameValidation"
              type="text"
              placeholder="Voornaam"
              required
              class="mb-3 shadow-sm"
            ></b-form-input>
            <b-form-invalid-feedback :state="fNameValidation">
              Alleen letters zijn toegestaan.
            </b-form-invalid-feedback>
          </div>
          <!-- lastName -->
          <div class="col-lg-6 col-sm-12">
            <b-form-input
              v-model="form.lastname"
              :state="lNameValidation"
              type="text"
              placeholder="Achternaam"
              required
              class="mb-3 shadow-sm"
            ></b-form-input>
            <b-form-invalid-feedback :state="lNameValidation">
              Alleen letters zijn toegestaan.
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="row">
          <!-- street -->
          <b-col cols="7" md="4">
            <b-form-input
              v-model="form.street"
              :state="streetValidation"
              type="text"
              placeholder="Straat"
              required
              class="mb-3 shadow-sm"
            ></b-form-input>
            <b-form-invalid-feedback :state="streetValidation">
              speciaal teken niet toegestaan
            </b-form-invalid-feedback>
          </b-col>
          <!-- house_number -->
          <b-col cols="5" md="4">
            <b-form-input
              v-model="form.house_number"
              :state="houseValidation"
              type="text"
              placeholder="Huisnr."
              required
              class="mb-3 shadow-sm"
            ></b-form-input>
          </b-col>
          <!-- city -->
          <div class="col-lg-4 col-sm-12">
            <b-form-input
              v-model="form.city"
              :state="cityValidation"
              type="text"
              placeholder="Plaats"
              required
              class="mb-3 shadow-sm"
            ></b-form-input>
          </div>
        </div>
        <div class="row">
          <!-- phone_number -->
          <div class="col-lg-6 col-sm-12">
            <b-form-input
              v-model="form.phone_number"
              :state="phoneValidation"
              type="tel"
              maxLength="10"
              placeholder="Telefoonnummer"
              required
              class="mb-3 shadow-sm"
            ></b-form-input>
            <b-form-invalid-feedback :state="phoneValidation">
              Telefoonnummer moet 10 getallen bevatten.
            </b-form-invalid-feedback>
          </div>
          <!-- email -->
          <div class="col-lg-6 col-sm-12">
            <b-form-input
              v-model="form.email"
              :state="emailValidation"
              type="email"
              placeholder="E-mail adres"
              required
              class="mb-3 shadow-sm"
            ></b-form-input>
            <b-form-invalid-feedback :state="emailValidation">
              Voer een geldig e-mailadres in
            </b-form-invalid-feedback>
          </div>
        </div>
        <!-- submit -->
        <div class="text-center mt-3 text-sm-right">
          <b-button
            variant="warning"
            size="lg"
            type="submit"
            class="btn-bg text-white shadow-lg px-4"
          >
            <b-spinner v-if="getLoader" /><span class="text-white" v-else
              >Berekening afronden &nbsp; ></span
            >
          </b-button>
        </div>
      </b-form>
      <img
        src="@/assets/RING Videodeurbel combine.png"
        class="d-none d-lg-block videodeurbel__img"
      />
    </div>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Form",
  props: {
    answers: Array,
  },
  data() {
    return {
      validationCheck: true,
      form: {
        language: "nl_NL",
        firstname: "",
        lastname: "",
        street: "",
        house_number: "",
        city: "",
        phone_number: "",
        email: "",
        answers: this.answers,
        site_subid: "",
        publisher_id: "",
        site_custom_url: "https://directveilig.verbeterthuis.nl/",
        site_custom_name: "DirectVeilig",
      },
    };
  },
  methods: {
    ...mapActions(["postLead"]),

    async onSubmit() {
      if (this.validationCheck) {
        let resp = await this.postLead({
          ...this.form,
          site_subid: this.site_subid,
          publisher_id: this.publisher_id,
        });
        if (resp.success == true) {
          this.$router.push("bedankt");
        }
      }
    },
  },
  computed: {
    ...mapGetters(["getLoader", "site_subid", "publisher_id"]),

    fNameValidation() {
      if (this.form.firstname == "") {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;
      if (this.form.firstname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    lNameValidation() {
      if (this.form.lastname == "") {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;
      if (this.form.lastname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    streetValidation() {
      if (this.form.street == "") {
        this.validationCheck = false;
        return null;
      }

      let streetRegex = /^[a-zA-Z ]*$/;
      if (this.form.street.match(streetRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    houseValidation() {
      if (this.form.house_number == "") {
        this.validationCheck = false;
        return null;
      }
      let houseRegex = "^[0-9]+$";
      if (this.form.house_number.match(houseRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    cityValidation() {
      if (this.form.city == "") {
        return null;
      }
      if (this.form.city != null) {
        this.validationCheck = true;
        return true;
      }
    },
    phoneValidation() {
      if (this.form.phone_number == "") {
        this.validationCheck = false;
        return null;
      }

      let phoneRegex = /^0\d{9}$/;
      if (this.form.phone_number == "0") {
        this.validationCheck = false;
        return true;
      } else if (this.form.phone_number.match(phoneRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    emailValidation() {
      if (this.form.email == "") {
        this.validationCheck = false;
        return null;
      }

      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)
      ) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.form-control {
  border: 1px solid #e3e3e3;
}
.form {
  & h4 {
    font-family: Roboto-Bold !important;
    span {
      color: #f7852c !important;
      text-decoration: underline;
    }
    @media only screen and (max-width: 766px) {
      font-size: 20px !important;
    }
  }
  & input {
    background: #e3e3e3 !important;
    border-radius: 10px !important;
  }
  & .btn-bg {
    background: #f7852c !important;
    border: #f97e20 !important;
    color: white !important;
    border-radius: 10px !important;
  }
  & .videodeurbel__img {
    position: absolute;
    right: -195px;
    bottom: -110px;
    width: 335px;
  }
}
</style>
